<template>

	<div>
		<div class="header filter">
			<div class="top top_mafi"><!--trans-->
				<div class="util shadow">
					<button
						@click="goBack"
						class="arrow_white"
					>
						<i class="icon icon-arrow-left"></i>
					</button>
				</div>
				<div class="save">
					<button
						@click="postCartel"
						class="btn_save"
					>{{ $language.common.next }}</button>
				</div>
			</div>
		</div>
		<div class="section_wrap pt-50">
			<!-- 커버등록 -->
			<div class="cartel_cover">
				<template
					v-if="step == 1"
				>
					<label class="box pa-5-10 radius-10 position-absolute size-px-12 bg-white-half" style="right: 10px; top: 10px; z-index: 9">
						<v-icon small class="">mdi-camera</v-icon>
						{{ $language.cartel.regist_cover_image }} <!-- 커버 이미지 등록 -->
						<InputFileNew class="fileform_hidden" accept="image/*" @change="setFile($event, 'mobile')" ref="file" />
					</label>

					<div
						v-if="item_cartel.mobile_cover_img_url"
						class="cover_background"
					>
						<img
							:src="item_cartel.mobile_cover_img_url"
							class="object-cover"
						/>
					</div>
				</template>
				<div
					v-else
					class="cover_background"
				>
					<div class="title_area">
						<div class="title_area_container">
							<h2>{{ item_cartel.cartl_name }}</h2>
						</div>
					</div>
					<img
						v-if="item_cartel.mobile_cover_img_url"
						:src="item_cartel.mobile_cover_img_url"
						class="object-cover"
					/>
				</div>

				<label class="box-profile-outline">
					<template
						v-if="step == 1"
					>
						<span class="box-profile-inline">
							<v-icon class="color-white">mdi-camera-outline</v-icon>
						</span>
						<InputFileNew type="file" class="fileform_hidden" accept="image/*" @change="setFile($event, 'profile')" ref="file" />
					</template>
					<img
						v-if="item_cartel.profle_img_url"
						:src="item_cartel.profle_img_url"
						class="object-cover"
					/>
				</label>
			</div>
			<template
				v-if="step == 1"
			>
				<div class="container mt-30">
					<div class="subindex_mainbox">
						<div class="input_area mt-30">
							<div class="bright_input">
								<div class="flrex-row justify-space-between">
									<!-- 웹 커버 이미지 -->
									<label for="cartelName">{{  $language.cartel.cover_web }}</label>

									<label class="box pa-5-10 radius-10 size-px-10 bg-white-half">
										<v-icon small class="">mdi-camera</v-icon>
										{{ $language.cartel.regist_cover_image }}
										<InputFileNew class="fileform_hidden" accept="image/*" @change="setFile($event, 'cover')" ref="file" file_upload_code_info="CM00700021" />
									</label>
								</div>

								<div
									class="input_utill overflow-hidden radius-10  flex-column justify-center items-center"
									style="height: 50px; background-color: #eee; padding: 0; border: 1px solid var(--light-Gray02)"
									:style="'background-image: url(' + item_cartel.cover_img_url + '); background-size: cover; background-position: center center'"
								>
									<div
										v-if="!item_cartel.cover_img_url"
										class="text-center"
									>
										<img
											:src="require('@/assets/image/cartel-cover-thumb.png')"
											style="width: 30px;" class="mt-10"
										/>
									</div>
								</div>

								<div class="input_area" style="padding-top: 10px">
									<p class="input_desc2 size-px-12">
										{{  $language.common.text_tip4 }}
									</p>
								</div>
							</div>
						</div>

						<!-- 카르텔이름 -->
						<div class="input_area">
							<div class="bright_input">
								<label for="cartelName">{{ $language.cartel.name }}</label>
								<div class="input_utill">
									<input
										v-model="item_cartel.cartl_name"
										type="text" id="cartelName" :placeholder="$language.cartel.name_require" maxlength="20"
									>
									<div class="input_utill_area">
										<div class="btn_del">
											<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
											<button
												v-if="item_cartel.cartl_name"
												@click="item_cartel.cartl_name = ''"
												class="active"
											><span class="hide">{{ $language.common.close }}</span></button>
										</div>
										<!-- TODO : 텍스트 수 카운트 필요 -->
										<span class="input_text_count">{{ item_cartel.cartl_name.length }}/20</span>
									</div>
								</div>
							</div>
						</div>
						<!-- //카르텔이름 -->
						<!-- 카르텔설명 -->
						<div class="input_area">
							<div class="bright_input">
								<label for="cartelDesc">{{ $language.common.explain_cartel }}</label>
								<div class="input_utill">
									<input
										v-model="item_cartel.cartl_desctn"
										type="text" id="cartelDesc" :placeholder="$language.cartel.explain_require" maxlength="40"
									>
									<div class="input_utill_area">
										<div class="btn_del">
											<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
											<button
												v-if="item_cartel.cartl_desctn"
												class="active"
												@click="item_cartel.cartl_desctn = ''"
											><span class="hide">{{ $language.cartel.name }}</span></button>
										</div>
										<!-- TODO : 텍스트 수 카운트 필요 -->
										<span class="input_text_count">{{ item_cartel.cartl_desctn.length }}/40</span>
									</div>
								</div>
							</div>
						</div>
						<!-- //카르텔설명 -->
						<!-- 가입승인 -->
						<div class="input_area">
							<template
								v-if="is_approval"
							>
								<div class="flex align_items_center justify_content_between">
									<div class="tit mb-0">{{ $language.auth.auto_join_if_approval }}</div>
									<p class="toggle-group h_unset" @click="entry_approval_fg = !entry_approval_fg">
										<!-- TODO : 수신 허용시 .on 클래스 추가 -->
										<span class="btn-toggle " :class="{ on: !entry_approval_fg}"><em class="hide">{{ $language.common.join_confirm.name }}</em></span>
									</p>
								</div>
								<p class="input_desc">
                  {{ $language.cartel.boss_approval_need_join.name }}
								</p>
							</template>
							<template
								v-else
							>
								<div class="flex align_items_center justify_content_between">
									<div class="tit mb-0">{{ $language.auth.auto_join_if_approval }}</div><p class="toggle-group h_unset">
									<!-- TODO : 수신 허용시 .on 클래스 추가 -->
									<span class="btn-toggle " ><em class="hide">{{ $language.cartel.boss_approval_need_join.name }}</em></span>
								</p>
								</div>
								<p class="input_desc">
									{{  entry_approval_chg_confirmation_message }}
								</p>

							</template>
						</div>
						<!-- //가입승인 -->
					</div>
				</div>
			</template>
			<template
				v-else-if="step == 2"
			>
				<div class="subindex_mainbox mt-30">
					<div class="container">
						<div class="input_area">
							<h4 class="tit">{{ $language.interested.cartel_select }}</h4>
						</div>
						<ul class="ct_interest">
							<li>
								<div class="bright_radio interest_area">
									<input
										v-model="concern_sphere_code"
										value="CM00400001"
										type="radio" id="music"
									>
									<label for="music">
										<strong>Music</strong>
										<p>{{ $language.interested.music }}</p>
									</label>
								</div>
							</li>
							<li>
								<div class="bright_radio interest_area">
									<input
										v-model="concern_sphere_code"
										value="CM00400002"
										type="radio" id="art"
									>
									<label for="art">
										<strong>Art</strong>
										<p>{{ $language.interested.art }}</p>
									</label>
								</div>
							</li>
							<li>
								<div class="bright_radio interest_area">
									<input
										v-model="concern_sphere_code"
										value="CM00400003"
										type="radio" id="fashion"
									>
									<label for="fashion">
										<strong>Fashion</strong>
										<p>{{ $language.interested.fashion }}</p>
									</label>
								</div>
							</li>
							<li>
								<div class="bright_radio interest_area">
									<input
										v-model="concern_sphere_code"
										value="CM00400004"
										type="radio" id="influence"
									>
									<label for="influence">
										<strong>Influence</strong>
										<p>{{ $language.interested.influence }}</p>
									</label>
								</div>
							</li>
						</ul>


					</div>
				</div>
			</template>

			<button
			></button>
		</div>
	</div>
</template>
<script>
import InputFileNew from "@/components/InputFileNew";
export default {
	name: 'mafia119'
	,
	components: {InputFileNew},
	props: ['callBack', 'user']
	, data: function(){
		return {
			program: {
				name: this.$language.common.cartel_establish
				, title: ''
				, type: 'cartel_sub'
				, not_footer: true
				, not_header: true
				, is_next: true
				, callBack: {
					name: 'cartel'
					, step: 1
				}
			}
			, item_cartel: {
				step: 1
				, cartl_name: ''
				, cartl_desctn: ''
				, entry_approval_fg: false
				, concern_sphere_code: []
				, user_id: this.user.id
				, cartl_mobile_cover_img_url: ''
			}
			, cartel_img: ''
			, concern_sphere_code: []
			, step: 1
			, upload_file: ''
			, entry_approval_fg: false
			, entry_approval_chg_fg: false
			, entry_approval_chg_confirmation_message: ''
		}
	}
	, computed: {
		is_approval: function(){
			let t = true

      //FIXME 여기는 언어팩 적용시켜서 고쳐야함 (일단 보류)
			if(this.entry_approval_chg_confirmation_message.indexOf('가입대기중') > -1){
				t = false
			}
			return t
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data

					this.$set(this.item_cartel, 'cover_img_url', result.data.cartl_web_img_url)
					this.$set(this.item_cartel, 'mobile_cover_img_url', result.data.cartl_mobile_img_url)
					this.$set(this.item_cartel, 'profle_img_url', result.data.cartl_profle_img_url)

					this.concern_sphere_code = result.data.cartl_concern_sphere_code
					this.entry_approval_fg = result.data.entry_approval_fg == 'Y' ? true : false

					switch (this.item_cartel.cartl_concern_sphere_code){
						case 'CM00400001': this.$set(this.item_cartel, 'type', 'music'); break
						case 'CM00400002': this.$set(this.item_cartel, 'type', 'art') ; break
						case 'CM00400003': this.$set(this.item_cartel, 'type', 'fashion'); break
						case 'CM00400004': this.$set(this.item_cartel, 'type', 'influence'); break
					}

					await this.getCartelApproval()

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getCartelApproval: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_approval
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, entry_approval_fg: 'N'
					}
					, type: true
				})

				if(result.success){
					this.entry_approval_chg_fg = result.data.entry_approval_chg_fg
					this.entry_approval_chg_confirmation_message = result.data.entry_approval_chg_confirmation_message
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setFile: function(e, type){

			if(type == 'cover'){
				this.$set(this.item_cartel, 'cover_img_url', e)
				this.$set(this.item_cartel, 'upload_cover_img_url', e)
			}else if(type == 'mobile') {
				this.$set(this.item_cartel, 'mobile_cover_img_url', e)
				this.$set(this.item_cartel, 'upload_mobile_cover_img_url', e)
			}else{
				this.$set(this.item_cartel, 'profle_img_url', e)
				this.$set(this.item_cartel, 'upload_profle_img_url', e)
			}
		}
		, postFile: async function(){
			return true
		}
		, postCartel: async  function(){
			console.log('postCartel', this.item_cartel)
			if(this.step == 1) {
				if(!this.item_cartel.mobile_cover_img_url && !this.item_cartel.upload_mobile_cover_img_url){
					this.$bus.$emit('notify', { type: 'error', message: this.$language.cartel.image_require})
					return false
				}else if (!this.item_cartel.cartl_name || this.item_cartel.cartl_name == '') {
					this.$bus.$emit('notify', { type: 'error', message: this.$language.cartel.name_require})
					return false
				} else if (!this.item_cartel.cartl_desctn || this.item_cartel.cartl_desctn == '') {
					this.$bus.$emit('notify', { type: 'error', message: this.$language.cartel.explain_require})
					return false
				} else {
					this.step = 2
					return false
				}
			}else{
				if (!this.concern_sphere_code) {
					this.$bus.$emit('notify', { type: 'error', message: this.$language.cartel.interested_require})
					return false
				}
			}

			if(await this.postFile()){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_modify
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, cartl_name: this.item_cartel.cartl_name
							, cartl_desctn: this.item_cartel.cartl_desctn
							, entry_approval_fg: this.entry_approval_fg ? 'Y' : 'N'
							, concern_sphere_code: this.concern_sphere_code
							, cartl_profle_img_url: this.item_cartel.upload_profle_img_url
							, cartl_cover_img_url: this.item_cartel.upload_cover_img_url
							, cartl_mobile_cover_img_url: this.item_cartel.upload_mobile_cover_img_url
						}
						, type: true
					})

					if(result.success){
						// document.location.href = '/cartel/mafia074/' + this.$route.params.idx
						this.$bus.$emit('to', { name: 'mafia074', params: {idx: this.$route.params.idx}})
					}else{
						throw result.message
					}

				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}

		,goBack: function(){
			if(this.step == 1){
				this.$emit('goBack')
			}else{
				this.step = 1
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getCartel()
	}
}
</script>

<style>

.box-profile-outline {
	position: absolute; left: calc(50% - 45px); bottom: -45px; z-index: 999;
	display: flex; flex-direction: column; align-items: center; justify-content: center;
	background-color: #2C2C2C; width: 95px; height: 95px;
	border-radius: 100%;
	overflow: hidden;
}

.box-profile-inline {
	position: absolute; left: calc(50% - 28px); top: calc(50% - 28px); z-index: 999;
	display: flex; flex-direction: column; align-items: center; justify-content: center;
	width: 56px; height: 56px;
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 100%;
}
.box-profile-outline img {
	object-fit: cover;
}
</style>